import Layout from "../components/Layout";
import {BrowserRouter, Routes, Route} from "react-router-dom"
import Home from "../containers/Home";
import Contact from "../components/Contact";
import UseContexto from "../context/UseContexto";
import Example from "../components/Example";


function App() {
  return (
    <div>
      {/* <div className='app-cursor' style={{left: cursorX+'px', top: cursorY+'px'}}/> */}
      <BrowserRouter>
      <UseContexto>        
          <Layout>
            <Routes>
              <Route exact path="/" element={<Home/>}></Route>
              <Route exact path="/casos-estudio" element={<Example/>}></Route>
              <Route exact path="/contact" element={<Contact/>}></Route>
            </Routes>
          </Layout>
        </UseContexto>
      </BrowserRouter>
    </div>
  );
}

export default App;
