import React from 'react'
import "../assets/css/Skill.css"

export default function Skill() {
  return (
    <> 
    <div className="skill-container">
        <p className='font-sec'>¡Hola! Soy Guadalupe Sandoval.
            Trabajo como diseñadora UX/UI especializada en crear experiencias de usuarios. Me encanta diseñar interfaces que combinen tendencia y funcionalidad.
            <br></br>
            Tengo experiencia como desarrolladora Full Stack y disfruto de colaborar con expertos en desarrollo para llevar las ideas más innovadoras a la realidad.</p>
    </div>
    </>
  )
}
