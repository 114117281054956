import { motion, useTransform, useScroll } from "framer-motion";
import { useRef } from "react";
import './../assets/css/prueba.css'
import avatar from './../assets/static/avatar.png'

const Example = () => {
  return (
    <div className="bg-container">
      <div className="container">
        <span className="txt">
          Casos de estudio
        </span>
      </div>
      <HorizontalScrollCarousel />
      <div className="container">
        <span className="txt">
          Figma
        </span>
      </div>
    </div>
  );
};

const HorizontalScrollCarousel = () => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
  });

  const x = useTransform(scrollYProgress, [0, 1], ["-100%", "100%"]);

  return (
    <section ref={targetRef} className="section-container">
      <div className="card-container">
        <motion.div style={{ x }} className="axis-x">
          {projects.map((card, id) => {
            return <Card card={card} key={id} />;
          })}
        </motion.div>
      </div>
    </section>
  );
};

const Card = ({ card }) => {
  return (
    <motion.div
      whileHover={{scale:1.1, originX:0, color:'#ddfe55', marginRight:60}}
        transition={{
        type:'spring',
        stiffness: 50}}
      key={card.id}
      className="card"
    >
      <div
        style={{
          backgroundImage: `url(${card.url})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="card-info"
      ></div>
      <div className="title">
        <img src={avatar} alt="" />
        <p>{card.date}</p>
        <p className="txt-title">
          {card.tittle}
        </p>
      </div>
    </motion.div>
  );
};

export default Example;

const projects = [  
  {
    tittle: 'Chambaloo',
    description: 'Aplicaciones de una sola página con ReactJS que utilizan API REST creadas con Node.js',
    date: '2025',
    link: 'https://github.com/guadasandoval/fullStackOpen',
    url:{avatar},
    id:1
  },
  {
  tittle: 'Booking App',
  description: 'Proyecto final de curso de Yannick Gregorie',
  date: '2024',
  link: 'https://mentastudio.vercel.app/',
  url:{avatar},
  id:2
  },
  {
    tittle: 'Abuelos TEC',
    description: 'Página de reservas. Desarrollado con ReactJS, Tailwind CSS y Typescript',
    date: '2020',
    link: 'https://github.com/guadasandoval/planify',
    url:{avatar},
    id:3
  },
  {
    tittle: 'Oráculo',
    description: 'Proyecto personal creado para una muestra anual de cerámica',
    date: '2023',
    link: 'https://oraculo-sigma.vercel.app/',
    url:{avatar},
    id:4
  },

];